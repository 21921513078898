<script lang="ts" setup>
import { z } from 'zod'

definePageMeta({
  title: 'Sign In',
  layout: 'auth',
  auth: { unauthenticatedOnly: true, navigateAuthenticatedTo: '/apps' },
  unPaidAccess: true,
  uncheckOnboarding: true,
})

const { signIn } = useAuth()

const email = ref('')
const error = ref('')
const emailSchema = z.string().email()
watch(email, (value) => {
  const { success } = emailSchema.safeParse(value)
  if (!success) {
    error.value = 'Invalid email address'
  }
  else {
    error.value = ''
  }
})
</script>

<template>
  <NuxtLayout>
    <div class="mx-auto grid w-[350px] gap-6">
      <div class="flex items-center justify-center gap-2">
        <NuxtImg src="/logo_pink.svg" class="size-16" />
      </div>
      <div class="grid gap-2 text-center">
        <h1 class="text-3xl font-bold">
          Login
        </h1>
        <p class="text-balance text-base-content/70">
          Enter your email below to login to your account
        </p>
      </div>
      <div class="grid gap-4">
        <div class="grid gap-2">
          <label for="email">Email</label>
          <input
            id="email"
            v-model="email"
            type="email"
            placeholder="m@example.com"
            required
            class="input input-bordered"
          >
        </div>
        <span class="text-sm text-red-500">{{ error }}</span>
        <button class="btn btn-primary flex w-full items-center gap-x-2" :disabled="Boolean(!email.length || error.length)" @click.prevent="signIn('email', { email })">
          <iconify-icon icon="lucide:mail" class="text-lg" />
          Login With Email
        </button>
        <div class="divider my-6">
          OR
        </div>
        <button class="btn btn-neutral flex w-full items-center gap-x-2" @click.prevent="signIn('github')">
          <iconify-icon icon="lucide:github" class="text-lg" />
          Login With Github
        </button>
      </div>
    </div>
  </NuxtLayout>
</template>
